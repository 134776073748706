<template>
 <div class="content " @click.stop="video_bf()">
   <Nav></Nav>
   <div class="cont_video">
     <video ref="videoPlayer" data-v-07cc480a="" muted="muted" autoplay="autoplay" loop="loop" x5-playsinline="true" playsinline="true" webkit-playsinline="true" x-webkit-airplay="allow" preload="auto" x5-video-player-type="h5" x5-video-player-fullscreen="true"><source data-v-07cc480a=""  src="" type="video/mp4"></video>
   </div>
    <div class="cont_yp" @click="yp_but">
      <img v-if="isYp" src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/41.png" />
      <img v-else src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/40.png" />
    </div>
   <div class="cont_title" >
     <p>{{user.title}}</p>
     <p>{{user.subtitle}}</p>

   </div>
   <div class="cont_down">

     <div class="down_ios" @click="appxz(1)" >
       <img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/14.png"/>
       <div class="down_code" v-if="android">
         <img :src="user.appewm" alt="">
       </div>
     </div>
     <div class="down_android"  @click="appxz(2)" >
       <img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/6.png" />
       <div class="down_code" v-if="ios">
         <img :src="user.appewm" alt="">
       </div>
     </div>

     <div class="down_app_code">
        <div class="app_code_div" v-for="(item,index) in app_list" :key="index" @mouseenter="app_list[index].type = 1" @mouseleave="app_list[index].type = 0">
          <img class="app_code_div_logo" :src="item.img" alt="">
          <div class="app_code_div_dialog" v-if="item.type">
            <img :src="item.ewm" alt="">
          </div>
        </div>
     </div>

   </div>
   <div class="cont_mountain" ref="dx1">
      <div  class="mountain_div ">
         <p class="mountain_div_title animate__animated animate__delay-1s animate__fadeInUp ">一吸一呼 <br> 让心回家</p>
         <p class="mountain_div_titles  animate__animated animate__delay-1s animate__fadeInUp">寻找一个属于自己灵魂栖息地</p>

         <img class="animate__animated animate__delay-1s animate__fadeInUp" src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/29.png" alt="">
      </div>
   </div>
   <div class="cont_title_1">
         <div class="title_cont">
           <el-carousel :interval="5500" direction="vertical" arrow="never" indicator-position="none" style="height: 6.5rem;" >
             <el-carousel-item v-for="(item,index) in yl_list" :key="index">
                <div v-html="item.info"></div>
             </el-carousel-item>
           </el-carousel>
         </div>
   </div>
   <div class="cont_mountain_1" ref="dx2">
       <div  v-show="dx2" class="mountain_div ">
            <div class="mountain_1_title-1 animate__animated animate__delay-1s animate__fadeInUp">博古通今 <br> 包罗万象 冥想课程</div>
            <div class="mountain_1_title-2 animate__animated animate__delay-1s animate__fadeInUp">从心理、睡眠、情绪、健康、身心 多方面心灵练习获得安静丰盛的冥 想力量。</div>
       </div>

   </div>
   <div class="cont_title_1">
     <div class="title_cont">

       <el-carousel :interval="4500" direction="vertical" arrow="never" indicator-position="none" style="height: 6.5rem;" >
         <el-carousel-item v-for="(item,index) in yl_list" :key="index">
           <div v-html="item.info"></div>
         </el-carousel-item>
       </el-carousel>

     </div>
   </div>
   <div class="cont_mountain" ref="dx3">
     <div  v-show="dx3" class="mountain_divs ">
       <p class="mountain_div_title animate__animated animate__delay-1s animate__fadeInUp">一段音律 </p>
       <p class="animate__animated animate__delay-1s animate__fadeInUp">与大自然来一次共鸣平复心灵</p>
       <p></p>
       <p class="mountain_div_titles animate__animated animate__delay-1s animate__fadeInUp">心灵的声音如同大自然的天籁之音，它们悠扬地飘荡 在空气中，时而轻盈，时而激荡，沉浸其中感受当下 接受自我。</p>
       <img class="animate__animated animate__delay-1s animate__fadeInUp" src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/32.png" alt="">
     </div>
   </div>
   <div class="cont_title_2">
     我呼吸  我行走  我发声  我存在
   </div>
   <div class="cont_map" ref="dx4">
     <div class="map_cont">
       <div class="map_roll">
         <div class="map_roll_div" ref="rollHeight" :style="{ transitionDuration: `${transitionDuration}ms`, transform: `translateY( -${translateX}px)`}">
            <div class="roll_item" >
              <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
              <div>
                <p>这里是张**</p>
                <p>刚完成了16分钟快速放松休息</p>
              </div>
            </div>
           <div class="roll_item" >
             <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
             <div>
               <p>这里是李**</p>
               <p>刚完成了12分钟快速放松休息</p>
             </div>
           </div>
           <div class="roll_item" >
             <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
             <div>
               <p>这里是范**</p>
               <p>刚完成了17分钟快速放松休息</p>
             </div>
           </div>
           <div class="roll_item" >
             <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
             <div>
               <p>这里是邓**</p>
               <p>刚完成了8分钟快速放松休息</p>
             </div>
           </div>
           <div class="roll_item" >
             <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
             <div>
               <p>这里是金**</p>
               <p>刚完成了36分钟快速放松休息</p>
             </div>
           </div>
           <div class="roll_item" >
             <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
             <div>
               <p>这里是杨**</p>
               <p>刚完成了24分钟快速放松休息</p>
             </div>
           </div>
           <div class="roll_item" >
             <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
             <div>
               <p>这里是用朱**</p>
               <p>刚完成了19分钟快速放松休息</p>
             </div>
           </div>
           <div class="roll_item" >
             <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
             <div>
               <p>这里是蔡**</p>
               <p>刚完成了42分钟快速放松休息</p>
             </div>
           </div><div class="roll_item" >
           <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
           <div>
             <p>这里是用王**</p>
             <p>刚完成了27分钟快速放松休息</p>
           </div>
         </div>
           <div class="roll_item" >
             <img src="https://img2.baidu.com/it/u=741224743,1343438594&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt="">
             <div>
               <p>这里是孙**</p>
               <p>刚完成了37分钟快速放松休息</p>
             </div>
           </div>


         </div>
       </div>
        <p  v-show="dx4"  class="map_title animate__animated animate__delay-1s animate__fadeInUp">目前</p>
        <p  v-show="dx4" class="map_title_1 animate__animated animate__delay-1s animate__fadeInUp">{{user.notitle}}全球用户数量 <br>已累计超过 <CountTo :startVal='startVal' :endVal='endVal' :duration='duration' /></p>
     </div>
   </div>
   <div class="cont_yd" v-if="yd" @click="yd=false">
     <img src="https://qiniu.fanyu889.com/cxmx/assets/ydimg/31.png" />
   </div>
    <Btm></Btm>
 </div>
</template>

<script>
import Nav from "@/components/yd-Nav/index"
import Btm from "@/components/yd-Btmfooter/index"
import CountTo from 'vue-count-to'
import {all_list, all_mxyl} from "@/api/request/all";
import {mapState} from "vuex";
export default {
  name: "home",
  data(){
    return{
      app_list:[
        {img:'https://qiniu.fanyu889.com/cxmx/assets/pcimg/12.png',type:0},
        {img:'https://qiniu.fanyu889.com/cxmx/assets/pcimg/15.png',type:0},
        {img:'https://qiniu.fanyu889.com/cxmx/assets/pcimg/24.png',type:0},
        {img:'https://qiniu.fanyu889.com/cxmx/assets/pcimg/28.png',type:0},
      ],
      translateX:0,
      transitionDuration:500,
      isYp:true,
      dx1:false,
      dx2:false,
      dx3:false,
      dx4:false,
      user:'',
      yl_list:'',
      ios:0,
      android:0,
      startVal:10,
      endVal: 1000000,
      duration: 100000,
      yd:false,
      bfshow:true

    }
  },
  computed: {
    ...mapState(['user','seo']),
  },
  metaInfo() {
    return {
      title: this.seo[0].title || this.user.indextitle,
      meta: [
        { name: 'description', content: this.seo[0].description ||this.user.drp},
        { name: 'keywords', content:this.seo[0].keywords ||this.user.seo}
      ]
    };
  },
  components:{
    Nav,
    Btm,
    CountTo
  },
  created() {
    this.all_user();
  },
  mounted() {
    this.all_yl();
    this.user_roll();
    window.addEventListener('scroll', this.handleScroll);
  },
  methods:{
    video_bf(){

      if (this.bfshow){
        this.bfshow =false;
        let isWechatiOS = false;
        if (/iPhone|iPad|iPod/.test(navigator.userAgent) && /MicroMessenger/i.test(navigator.userAgent)) {
          isWechatiOS = true;
        }
        if (!isWechatiOS) {
          // 如果是在苹果设备上的微信浏览器
          this.$nextTick(()=>{
            if (this.isYp == true){
              this.$refs.videoPlayer.muted =true;
              this.$refs.videoPlayer.play();
            }
          })
        }
      }
    },
    //app下载
    appxz(val){
      if (val == 1){
         if (/(micromessenger)/i.test(navigator.userAgent)){
           this.yd=true;
         }else {
           window.location.href='https://apps.apple.com/cn/app/%E5%88%9D%E5%BF%83%E5%86%A5%E6%83%B3/id6464159165'
         }

      }else {
        window.location.href='https://sj.qq.com/appdetail/uni.UNI39BA3F5'
        //window.open("https://sj.qq.com/appdetail/uni.UNI39BA3F5");
      }
    },
    //数据请求
    all_user(){
      let list={}
      all_list(list).then((res)=>{
        if (res.status == 1){
          this.user =res.data;
          this.$refs.videoPlayer.src=res.data.ydbgurl;
          this.$nextTick(()=>{
            this.$refs.videoPlayer.muted =true;
            this.$refs.videoPlayer.play();
          })
          this.app_list[0].ewm=res.data.gzhewm;
          this.app_list[1].ewm=res.data.sphewm;
          this.app_list[2].ewm=res.data.wxewm;
          this.app_list[3].ewm=res.data.dyewm;
        }
      })
    },
    //冥想语录
    all_yl(){
      let list={}
      all_mxyl(list).then((res)=>{
        if (res.status == 1){
          for (let i=0; i<res.data.length; i++){
            res.data[i].info= this.convertNewLinesToBrTags(res.data[i].info);
          }
          this.yl_list=res.data;
        }
      })
    },
    //正则转换
     convertNewLinesToBrTags(text) {
       return text.replace(/(\r\n|\n|\r)/gm, "<br/>");
     },
    //背景音乐开启关闭
    yp_but(){
      this.isYp =!this.isYp;
      if (this.isYp){
        this.$nextTick(()=>{
          this.$refs.videoPlayer.muted =true;
          this.$refs.videoPlayer.play();
        })
      }else {
        this.$nextTick(()=>{
          this.$refs.videoPlayer.muted =false;
          this.$refs.videoPlayer.play();
        })
      }


    },
    //自动轮播
    user_roll(){
      // 滚动项个数
      const switeCount = 10;
      const translateValue = this.$refs.rollHeight.offsetHeight/switeCount;
      setInterval(() => {
        this.translateX =this.translateX + translateValue
        this.transitionDuration=500;
         if ( this.translateX == (translateValue * (switeCount-4)).toFixed(1)){
             setTimeout(()=>{
               this.transitionDuration = 0;
               this.translateX = translateValue;
             },500)


         }
      }, 2000)

    },
    //页面滚动监听 触发动效
    handleScroll() { // 实现当滚动到指定位置，触发动画
      // let scrollTop =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 获取窗口滚动条高度
      // this.gdjz('earth', 'earth animated bounceInDown', 50)
      let _this = this
      let refArray = [
        {ref: 'dx1', show: 'dx1'},
        {ref: 'dx2', show: 'dx2'},
        {ref: 'dx3', show: 'dx3'},
        {ref: 'dx4', show: 'dx4'},
      ]
      refArray.forEach((r) => {
        _this.gdjz(r.ref, 20, () => {
          _this[r.show] = true
        })
      })
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div] // 等同于document.querySelector('.earth') true
      if(dom){
        var a,b,c,d;
        d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset)
        b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
        if( b + c > a ) {
          callback && callback()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
 .content{
    padding-top: 1px;
    overflow: hidden;
   .cont_yd{
     position: fixed;
     left: 0;
     right: 0;
     top: 0;
     bottom: 0;
     margin: auto;
     background: rgba(0, 0, 0, 0.5);
     z-index: 99999;
     img{
       width: 7.5rem;
       height: 5rem;
       position: absolute;
       top: 0;
       right: 1rem;
     }
   }
   .cont_video{
     width: 100%;
     position: fixed;
     z-index: -1;
     top: 0;
     video{
       width: 100%;
       height: 100%;
     }
   }
   .cont_yp{
     width: 1.5rem;
     height: 1.5rem;
     position: absolute;
     top: 3rem;
     right: 0.5rem;
     img{
       width: 1.5rem;
       height: 1.5rem;
     }
   }
   .cont_title{
     width: 100%;
     margin: 4.45rem auto 0;
     text-align: center;
     p:nth-child(1){
       font-weight: bold;
       font-size: 1.33rem;
       color: #FFFFFF;
       margin: 0;
     }
     p:nth-child(2){
       font-weight: bold;
       font-size: 0.65rem;
       color: #FFFFFF;
       line-height: 1.39rem;
       letter-spacing: 2px;
     }
   }
   .cont_down{
     width: 10.84rem;
     margin: 1.37rem auto  1.55rem;
      display: flex;
     justify-content: space-around;
     flex-wrap: wrap;

     .down_ios{
       width: 4.5rem;
       height: 1.7rem;
       position: relative;
       img{
         width: 4.5rem;
         height: 1.7rem;
       }
       .down_code{
         background: url('https://qiniu.fanyu889.com/cxmx/assets/pcimg/34.png') top center no-repeat;
         background-size: 100%;
         width: 4.5rem;
         height: 4.6rem;
         position: absolute;
         top: -4.8rem;
         left: 50%;
         transform: translateX(-50%);
         text-align: center;
         img{
           width: 4.0rem;
           height: 4.0rem;
           margin-top: 0.2rem;
         }
       }
     }
     .down_android{
       width: 4.5rem;
       height: 1.7rem;
       position: relative;
       img{
         width: 4.5rem;
         height: 1.7rem;
       }
       .down_code{
         background: url('https://qiniu.fanyu889.com/cxmx/assets/pcimg/34.png') top center no-repeat;
         background-size: 100%;
         width: 4.5rem;
         height: 4.6rem;
         position: absolute;
         top: -4.8rem;
         left: 50%;
         transform: translateX(-50%);
         text-align: center;
         img{
           width: 4.0rem;
           height: 4.0rem;
           margin-top: 0.2rem;
         }
       }
     }
      .down_app_code{
        width: 10.84rem;
        height: 1.53rem;
        display: flex;
        justify-content: space-between;
        margin-top: 0.98rem;
        .app_code_div{
          width: 1.53rem;
          height: 1.53rem;
          position: relative;
          .app_code_div_logo{
            width: 1.53rem;
            height:1.53rem;
          }
          .app_code_div_dialog{
            background: url('https://qiniu.fanyu889.com/cxmx/assets/pcimg/34.png') top center no-repeat;
            background-size: 100%;
            width: 4.5rem;
            height: 4.6rem;
            position: absolute;
            top: -4.8rem;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            img{
              width: 4.0rem;
              height: 4.0rem;
              margin-top: 0.2rem;
            }
          }
        }
      }
   }
   .cont_mountain{
     background: url('https://qiniu.fanyu889.com/cxmx/assets/ydimg/23.png') top center no-repeat;
     background-size: 100%;
     width: 100%;
     height: 14.2rem;

     .mountain_div{
       width: 100%;
       position: relative;
       height: 14.2rem;
       margin: 0 auto;
       .mountain_div_title{
         max-width: 6rem;
         font-weight: bold;
         font-size: 1.15rem;
         color: #333333;
         line-height: 1.3rem;
         border-bottom:1px solid #C1C1C1;
         padding-bottom: 0.5rem;
         position: absolute;
         top: 4.6rem;
         left: 1.13rem;
       }
       .mountain_div_titles{
         font-weight: bold;
         font-size: 0.65rem;
         position: absolute;
         top:8.3rem;
         left:1.1rem ;
       }
       img{
         width: 5.7rem;
         height: 11.43rem;
         position: absolute;
         right: 1.13rem;
         top: 11%;
         //transform: translateY(-50%);
       }

     }
     .mountain_divs{
       width: 100%;
       position: relative;
       height: 14.2rem;
       margin: 0 auto;
       p:nth-child(1){
         font-weight: bold;
         font-size: 1.15rem;
         color: #333333;
         line-height: 1.3rem;
         position: absolute;
         top: 2.98rem;
         left: 1.08rem;
       }
       p:nth-child(2){
         font-size: 0.7rem;
         color: #333333;
         font-weight: bold;
         position: absolute;
         top: 4.5rem;
         left: 1.08rem;
       }
       p:nth-child(3){
         width: 4.5rem;
         height: 0.05rem;
         background: #C1C1C1;
         border-radius: 0.03rem;
         position: absolute;
         left: 1.08rem;
         top: 6.1rem;
       }
       p:nth-child(4){
         width: 9.15rem;
         height: 4.22rem;
         font-weight: 500;
         font-size: 0.65rem;
         color: #333333;
         line-height: 1.1rem;
         position: absolute;
         left: 1.08rem;
         top: 7rem;
       }
       img{
         width: 5.7rem;
         height: 11.43rem;
         position: absolute;
         right: 1.13rem;
         top: 11%;
         //transform: translateY(-50%);
       }
     }
   }
   .cont_title_1{
     background: url('https://qiniu.fanyu889.com/cxmx/assets/ydimg/22.png') top center no-repeat;
     background-size: 100%;
     width: 100%;
     height: 6.5rem;
     .title_cont{
       height: 6.5rem;
       width: 13rem;
       margin: 0 auto;
         .el-carousel__item{
           width: 13rem;
           height: 6.5rem;
           div{
             position: absolute;
             top: 50%;
             left: 50%;
             transform: translateY(-50%) translateX(-50%);
             width: 13rem;
             font-weight: bold;
             font-size: 0.6rem;
             color: #FFFFFF;
             line-height: 1.05rem;
           }
         }


     }

   }
   .cont_mountain_1{
     background: url('https://qiniu.fanyu889.com/cxmx/assets/ydimg/27.gif') top center no-repeat;
     background-size: 100%;
     width: 100%;
     height: 26.5rem;
     position: relative;
     .mountain_div{
       height: 26.5rem;
       position: relative;
       .mountain_1_title-1{
         font-weight: bold;
         font-size: 1.15rem;
         color: #222222;
         line-height: 1.5rem;
         position: absolute;
         top: 0.7rem;
         left: 50%;
         width: 10.05rem;
         text-align: center;
        // transform: translateX(-50%);
         margin-left: -5.025rem;
       }
       .mountain_1_title-2{
         width: 14.35rem;
         height: 1.68rem;
         font-weight: 500;
         font-size: 0.65rem;
         color: #333333;
         line-height: 1.05rem;
         text-align: center;
         position: absolute;
         left: 50%;
        // transform: translateX(-50%);
         bottom: 1.4rem;
         margin-left: -7.175rem;

       }
     }
   }
   .cont_title_2{
    width: 100%;
     height: 5rem;
     font-weight: bold;
     font-size: 0.8rem;
     color: #FFFFFF;
     line-height: 5rem;
     text-align: center;
     letter-spacing: 0.1rem;
   }
   .cont_map{
     background: url('https://qiniu.fanyu889.com/cxmx/assets/ydimg/28.gif') top center no-repeat;
     background-size: 100%;
     width: 100%;
     height: 9.95rem;
     .map_cont{
      width: 100%;
       height: 9.95rem;
       margin: 0 auto;
       position: relative;
       .map_roll{
         width:8.7rem;
         height: 6.3rem;
         position: absolute;
         top:1.8rem;
         overflow: hidden ;
         left: 1rem;
         .map_roll_div{
           width: 100%;
           .roll_item{
             height: 1.5rem;
             display: flex;
             padding: 0.3rem 0;
             img{
               width: 1.5rem;
               height: 1.5rem;
               border-radius: 50%;
             }
             div{
               margin-left: 0.33rem;
               padding-top: 4px;
               p:nth-child(1){
                 font-weight: 500;
                 font-size: 0.6rem;
                 color: #333333;
               }
               p:nth-child(2){
                 font-weight: 500;
                 font-size: 0.5rem;
                 color: #333333;

               }
             }
           }
         }
       }
       .map_title{
         width: 2.25rem;
         height: 1rem;
         background: #FFA71F;
         border-radius: 0.23rem;
         text-align: center;
         line-height: 1rem;
         font-weight: bold;
         font-size: 0.62rem;
         color: #FFFFFF;
         position: absolute;
         top: 2.88rem;
         left: 10.5rem;
       }
       .map_title_1{
         width: 8.5rem;
         font-weight: bold;
         font-size: 0.65rem;
         color: #333333;
         line-height: 0.93rem;
         position: absolute;
         top: 4.38rem;
         left:10.5rem;
         span{
           font-weight: bold;
           font-size: 0.9rem;
           color: #FFA71F;
         }
       }
     }
   }
 }
</style>